<template>
  <div>
    <img class="top-image" :src="require(`@/assets/images/${categories[currentCategoryIndex].category}.png`)" />

    <!-- 切换分类 -->
    <div class="category-bar flex-row-between-center">
      <div :class="currentCategoryIndex === index ? 'category-active category-btn' : 'category-btn'" v-for="(item, index) in categories" :key="index" @click="handleChangeCategory(index)">{{ item.name }}</div>
    </div>

    <!-- 表单 -->
    <div class="form">
      <!-- 表单项 -->
      <div class="form-item flex-column-default-start">
        <div class="label">
          <span class="iconfont icon-required"></span>
          <span>标题</span>
        </div>
        <input type="text" v-model="formData.title" placeholder="请输入标题" />
      </div>

      <!-- 表单项 -->
      <div class="form-item">
        <div class="label">
          <span class="iconfont icon-required"></span>
          <span>内容</span>
        </div>
        <textarea maxlength="3000" v-model="formData.content" placeholder="请填写内容" />
      </div>

      <!-- 表单项 -->
      <div class="form-item flex-column-default-start">
        <div class="label">
          <span class="iconfont icon-required"></span>
          <span>联系人</span>
        </div>
        <input type="text" v-model="formData.username" placeholder="请填写联系人" />
      </div>

      <!-- 表单项 -->
      <div class="form-item flex-column-default-start">
        <div class="label">
          <span class="iconfont icon-required"></span>
          <span>联系电话</span>
        </div>
        <input type="text" v-model="formData.phoneNumber" placeholder="请填写联系电话" />
      </div>

      <!-- 表单项 -->
      <div class="form-item flex-column-default-start">
        <div class="label">
          <span class="iconfont icon-required"></span>
          <span>相关图片</span>
        </div>
        <!-- 图片块 -->
        <div class="image-block flex-row-default-center">
          <div class="show-image" v-for="(item, index) in formData.pictures" :key="index">
            <img class="image" :src="item" />
            <!-- 移除图片的按钮 -->
            <div class="remove-image flex-center-center" @click="handleDeleteImage(index)">×</div>
          </div>
          <!-- 选择图片上传按钮 -->
          <div v-if="formData.pictures.length < 4" class="add-image flex-center-center" @click="handleSelectImage">+</div>
        </div>
      </div>
    </div>

    <!-- 上传图片input -->
    <input v-show="false" ref="file" type="file" accept="image/*" @change="handleUploadImage" />

    <!-- 添加按钮 -->
    <div class="button-one" @click="handleAdd">发 布</div>
  </div>
</template>

<script>
import { uploadToOSS } from '@/request/upload'
import { Toast } from 'mint-ui'

export default {
  name: 'AddExchangeInfo',

  data () {
    return {
      currentCategoryIndex: 0,
      categories: [
        { name: '招聘', category: 'Recruitment' },
        { name: '项目合作', category: 'ProjectCooperation' },
        { name: '活动信息', category: 'ActivityInfo' },
        { name: '其它', category: 'Other' }
      ],
      formData: {
        title: '',
        content: '',
        username: '',
        phoneNumber: '',
        pictures: [],
        category: 'Recruitment'
      }
    }
  },

  created () {
    const userInfo = this.$store.state.userInfo
    if (userInfo) {
      this.formData.username = userInfo.name
      this.formData.phoneNumber = userInfo.phoneNumber
    }
  },

  methods: {
    // 修改分类
    handleChangeCategory (index) {
      this.currentCategoryIndex = index
      this.formData.category = this.categories[index].category
    },
    // 添加信息
    handleAdd () {
      if (this.formData.title === '') {
        Toast('标题不能为空')
        return
      }
      if (this.formData.content === '') {
        Toast('内容不能为空')
        return
      }
      if (this.formData.username === '') {
        Toast('联系人不能为空')
        return
      }
      if (this.formData.phoneNumber === '') {
        Toast('联系方式不能为空')
        return
      }
      this.api.addMemberExchangeInfo(this.formData).then(res => {
        Toast(res.msg)
        this.$router.back()
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.handleAdd() })
      })
    },
    // 选择图片
    handleSelectImage () {
      this.$refs.file.click()
    },
    // 删除图片
    handleDeleteImage (index) {
      this.formData.pictures.splice(index, 1)
    },
    // 上传图片
    async handleUploadImage () {
      if (this.$refs.file.files[0]) {
        const file = this.$refs.file.files[0]
        const res = await this.api.getAliOSSToken()
        await uploadToOSS(res.data, file).then(res => {
          this.formData.pictures.push(res)
        }).catch(() => {
          Toast('上传失败')
        })
      }
    }
  }
}
</script>

<style scoped>
.top-image {
  margin: 0;
}
.category-bar {
  padding: 0 2vw;
}
.category-btn {
  width: 23vw;
}

.card-item > input {
  width: 100%;
  font-size: 15px;
  color: var(--normal-font-color);
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
}
.card-item > input::placeholder {
  font-size: 15px;
}
.card-item > textarea::placeholder {
  font-size: 15px;
}
</style>
